.no-margin-no-padding {
  margin: 0px;
  padding: 0;
}
.display-boxs {
  transform: scale(0.5);
  left: 10vw;
  top: 23px;
  position: absolute;
  min-width: 250px;
  max-width: 2600px;
}
.display-log-btn {
  left: 35%;
  top: 280px;
  width: 50vw;
  min-width: 125px;
  max-width: 1800px;
  position: absolute;
}
.big-con {
  position: relative;
  top: -15vh;
  left: -9vw;
}

.heightBox800 {
  height: 800px;
}
.heightBox400 {
  height: 400px;
}
.heightBox200 {
  height: 200px;
}
.heightBox100 {
  height: 100px;
}
.displayNoneBox {
  display: none;
}
