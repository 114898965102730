.bakground {
  background-color: rgb(238, 238, 238);
  position: fixed;
  height: 100%;
  width: 100%;
}

.cont {
  position: absolute;
  top: 10%;
  margin-left: 20%;
  width: 65vw;
  overflow: auto;
  height: 100%;
}

.container {
  border: solid 1px black;
  position: absolute;
  width: 50vw;
  max-height: 84%;
  top: 50px;
}

.admin-var-width {
  display: flex;
  min-width: 300px;
}

.help-width {
  width: 54px;
  justify-content: center;
}

.modal {
  display: block;
  min-width: max-content;
}

@media (min-width: 1px) and (max-width: 767px) {
  .modalResponsive {
    z-index: 1050;
    min-width: fit-content;
    width: 35vw;
  }
  .minWidth {
    min-width: 100%;
  }
}

.usersListHeight {
  overflow: auto;
  max-height: 70vh;
  padding: 17px 17px 0px 17px;
}
