.a {
  width: 25vw;
  height: 200px;
  border: 1px solid;
  background-color: blue;
}
.b {
  width: 25vw;
  height: 400px;
  border: 1px solid;
  background-color: blue;
}

.img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.img2:hover {
  cursor: pointer;
}