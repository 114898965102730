.btn-pos {
  top: -70px;
  width: 100%;
  min-width: 250px;
  max-width: 2600px;
  position: absolute;
}
.font-size {
  font-size: 164%;
}
.font-size2 {
  font-size: 200%;
}
.marginLeft {
  margin-left: 40px;
}
.demo-txt {
  position: absolute;
  font-size: small;
  font-weight: 400;
  right: 8%;
}

@media (min-width: 1px) and (max-width: 365px) {
  .demo-txt {
    left: 196px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .userIcon {
    position: absolute;
    right: -47vw;
  }
  .userIcon2 {
    position: absolute;
    right: -47vw;
    top: 16vh;
  }
  .btnResponsive {
    top: -205px;
    width: 72vw;
  }
  .marginResponsive {
    margin-left: 0;
  }
  .logBtnResponsive {
    position: fixed;
    top: 214px;
  }
  .resetResponsive {
    width: 96vw;
  }
}

/* For loader */
.spinner-container {
  z-index: 2;
  width: 150px;
  height: 150px;
  position: relative;
  margin: 30px auto;
  overflow: hidden;
}

.spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-radius: 50%;
  border-top-color: #0d6efd;
  animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.79) infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
