.no-margin-no-padding {
  margin: 0px;
  padding: 0;
}

.img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

@media (min-width: 1px) and (max-width: 767px) {
  .inputResponsive {
    position: absolute;
    left: 0px;
  }
}
