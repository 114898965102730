.bakground {
  background-color: rgb(238, 238, 238);
  position: fixed;
  height: 100%;
  width: 100%;
}

.cont {
  position: absolute;
  top: 10%;
  margin-left: 20%;
  width: 65vw;
  overflow: auto;
  height: 100%;
}

.container {
  border: solid 1px black;
  position: absolute;
  width: 50vw;
  max-height: 84%;
  top: 50px;
}

.var-width {
  min-width: 116px;
  justify-content: center;
  flex-direction: column;
}
.help-width {
  width: 54px;
  justify-content: center;
}

.modal {
  display: block;
  min-width: max-content;
}

.ul {
  list-style-type: none;
}

.li {
  display: inline-block;
}

.preferences {
  display: none;
}

.preferencesModalContent {
  min-width: 164%;
}
.preferencesModalDialog {
  justify-content: center;
  display: flex;
}

.form-check-input[type="radio"] {
  margin-right: 7px;
}

.label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.popoverposition {
  scale: 0.75;
  translate: -10% 30%;
  border: black solid 2px;
  z-index: 2000;
}
.popoverposition2 {
  scale: 0.75;
  translate: -10% 35%;
  border: black solid 2px;
  z-index: 2000;
}
.popoverposition3 {
  scale: 0.75;
  translate: -10% 40%;
  border: black solid 2px;
  z-index: 2000;
}
.popoverposition4 {
  scale: 0.75;
  translate: -10% 45%;
  border: black solid 2px;
  z-index: 2000;
}

.label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 24px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.label img {
  height: 200px;
  width: 200px;
  object-fit: contain;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.label.checked {
  border-color: #ddd;
}

.label.checked:before {
  content: "✓";
  background-color: rgb(10, 88, 202);
  transform: scale(1);
}

.label.checked img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}

.widtForm {
  width: max-content;
}

@media (min-width: 1px) and (max-width: 767px) {
  .buttonResponsive {
    font-size: 1.7vw;
  }
  .widthResponsive {
    width: auto;
  }
  .widthPreferences {
    width: 85vw;
    min-width: 115%;
  }
}
