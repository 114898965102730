.popup-btn-pos {
  top: -3px;
  left: 44px;
  width: 100%;
  min-width: 250px;
  max-width: 2600px;
  position: absolute;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing[add="css"] {
  z-index: 2000;
}
