/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    min-height: 100vh;
    font-size: 5rem;
    display: grid;
    place-content: center;
  }
  
  input,
  button {
    font: inherit;
    padding: 0.5rem;
  }
  
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  input {
    text-align: center;
    max-width: 2.5em;
  }
  
  button {
    font-size: 2rem;
    margin: 0.5em 0 0.5em 0.5em;
    min-width: 2em;
  }
  
  button:first-child {
    margin-left: 0;
  } */

@media (min-width: 1281px) {
  /* CSS */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  /* CSS */
}

@media (min-width: 1px) and (max-width: 767px) {
  .modalResponsive {
    z-index: 1050;
    min-width: fit-content;
    width: 35vw;
  }
  .modalResponsive2 {
    width: 90vw;
  }
  .responsiveDisplayNone {
    display: none;
  }
  .buttonsGroupResponsive {
    width: 20%;
    right: 8%;
    position: fixed;
  }
  .topResponsive {
    top: 350px;
  }
}
